.driver-popover {
  padding: 24px;
}

.driver-popover-description {
  color: #520679;
}

span.driver-popover-navigation-btns {
  display: flex;
  justify-content: space-between;
}

button.driver-popover-next-btn {
  background: transparent;
  color: #B038FA;
  font-weight: bold;
  border: none;
  outline: none;
}
button.driver-popover-prev-btn {
  background: transparent;
  color: #B038FA;
  font-weight: bold;
  border: none;
  outline: none;
}
button.driver-popover-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #B038FA;
  font-size: 24px;
}
button.driver-popover-close-btn:hover {
  color: #70269e;
  font-size: 32px;
}
footer.driver-popover-footer {
  flex-direction: row-reverse;
  align-items: center;
}
span.driver-popover-progress-text {
  color: #B038FA;
  font-weight: bold;
}